import React from 'react';
import { Grid, Typography, Box, Divider, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import DropIcon from 'assets/icons/XIcon';

const Summary = ({ clientSelected, dateToPass, multiAppts, clickRemove }) => {
    let clientName = '';
    if (clientSelected) {
        clientName = clientSelected.firstName;
    }
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} id="summary-box-appts">
                <Box
                    sx={{
                        backgroundColor: '#fafafa', // Light grey background
                        border: '1px solid #d3d3d3', // Slightly darker border
                        borderRadius: '8px', // Rounded corners
                        padding: '16px', // Space inside the box
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow for subtle highlight
                    }}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ color: '#55c797', mb: 2, mt: 0.75 }}
                    >
                        Adding on to the following appointment
                        {multiAppts.length > 1 ? 's' : ''} for {clientName}:
                    </Typography>
                    {multiAppts.map((appt, index) => {
                        const start = dayjs(appt.startTimeToPass).format(
                            'h:mm A'
                        );
                        const end = dayjs(appt.endTimeToPass).format('h:mm A');
                        const lengthAppts = multiAppts.length;
                        let showDivider = false;
                        if (index < lengthAppts - 1) {
                            showDivider = true;
                        }
                        return (
                            <Grid
                                container
                                key={`grid-${index}`}
                                flexDirection={`row`}
                            >
                                <Grid item id="summary-appt" xs>
                                    <Typography key={index} variant="body2">
                                        <strong>Appt Type:</strong>{' '}
                                        {appt.apptTypeSelected.label}
                                        <br />
                                        <strong>Provider:</strong>{' '}
                                        {appt.instructorSelected.label}
                                        <br />
                                        <strong>Date:</strong> {dateToPass}
                                        <br />
                                        <strong>Time: </strong>
                                        {start} - {end}
                                        <br />
                                        <strong>Price: </strong>$
                                        {appt.apptTypeSelected.apptdefaultprice}
                                        <br />
                                        <br />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    id="drop-icon"
                                    xs={1}
                                    sx={{ mt: 1 }}
                                    onClick={() => {
                                        clickRemove(appt);
                                    }}
                                >
                                    <Tooltip title="Remove">
                                        <span>
                                            <DropIcon color="999999" />
                                        </span>
                                    </Tooltip>
                                </Grid>

                                {showDivider && (
                                    <Grid item xs={12}>
                                        <Divider
                                            key={`divider-${index}`}
                                            sx={{ mb: 2.5 }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        );
                    })}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Summary;
