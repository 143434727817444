import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@mui/material';

const PlusIcon = ({ shrinkage, fontWeight }) => {
    let shrink = 'shrink-0';

    if (shrinkage) {
        shrink = `shrink-${shrinkage}`;
    }
    return (
        <Grid item border={0}>
            <FontAwesomeIcon icon={faPlus} color="#e7b2a5" transform={shrink} />
        </Grid>
    );
};
export default PlusIcon;
