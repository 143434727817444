import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { shouldForwardProp } from '@mui/system';
import { useSelector, useDispatch } from 'store';
import {
    addToRecentsSearch,
    addOrUpdateSearchResults,
} from '../../store/slices/clientsearch';
import {
    clearPreviousClient,
    setCurrentClientProfileStudioAdmin,
} from '../../store/slices/currentclient';
import { getNewSearchResults } from '../../actions/studios/getNewSearchResults';
import { setPageIsLoading } from 'store/slices/loadingStatus';
import translate from 'helpers/translate';

const OutlineInputStyle = styled(TextField, { shouldForwardProp })(
    ({ theme }) => ({
        width: 434,
        height: 40,
        size: 'small',
        marginLeft: 0,
        paddingLeft: 6,
        paddingRight: 16,
        '& input': {
            background: 'transparent !important',
            paddingLeft: '4px !important',
            paddingTop: '4px !important',
            paddingBottom: '4px !important',
        },
        [theme.breakpoints.down('lg')]: {
            width: 250,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: 4,
            background:
                theme.palette.mode === 'dark'
                    ? theme.palette.dark[800]
                    : '#fff',
        },
    })
);

export default function AutocompleteSearch({ idtouse }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { results } = useSelector((state) => state.clientsearch);
    // const { userid } = useSelector((state) => state.currentclient);
    const { recents } = results;
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [searchTerm, setSearchTerm] = React.useState('');
    const [countLastSearchTerm, setCountLastSearchTerm] = React.useState(0);
    const [countSearchResults, setCountSearchResults] = React.useState(100);
    const [searchResults, setSearchResults] = React.useState(recents);
    const [searchedUserid, setSearchedUserid] = React.useState(5);
    // const searchOptions = [];
    React.useEffect(() => {
        const getresults = async () => {
            const result = await getNewSearchResults(
                config.dibsStudioId,
                searchTerm
            );
            if (result !== 0) {
                dispatch(addOrUpdateSearchResults(result));
                setSearchResults(result);
                setCountSearchResults(result?.length);
            }
            if (searchedUserid > 5) {
                // dispatch(setPageIsLoading(true));
                const urltolink = `/frontdesk/${dibsStudioId}/clients/${searchedUserid}`;
                navigate(urltolink);
            }
        };
        if (searchTerm.length > 1) getresults();

        return () => {
            setSearchedUserid(5);
        };
    }, [
        config.dibsStudioId,
        searchTerm,
        dispatch,
        countSearchResults,
        searchedUserid,
        navigate,
        dibsStudioId,
    ]);
    const filterOptions = createFilterOptions({
        stringify: ({ label, email, id, phone }) =>
            `${label} ${email} ${id} ${phone}`,
    });
    const renderSuggestion = (suggestion) => (
        <div
            key={`${suggestion.key}-${idtouse}-${Math.random()}`}
            id={`${idtouse}-search-display`}
        >
            <span style={{ fontWeight: '450' }}>{suggestion.label}</span>
            <div>
                <span style={{ fontWeight: '300', fontStyle: 'italic' }}>
                    {suggestion.email}
                </span>
            </div>
            <div>
                <span style={{ fontWeight: '300' }}>
                    {suggestion.phonelabel}
                </span>
            </div>
        </div>
    );
    // todo - check change here
    const setRecentOptions = (event, value) => {
        setSearchTerm(value?.label);
        setSearchedUserid(value?.id);
        if (value) {
            dispatch(clearPreviousClient());
            dispatch(setCurrentClientProfileStudioAdmin(value));
            dispatch(addToRecentsSearch(value));
        }
    };
    const testResetOfSearch = (valuefromfield) => {
        if (
            valuefromfield?.length < countLastSearchTerm ||
            countLastSearchTerm === 0
        ) {
            setCountSearchResults(100);
            if (valuefromfield?.length > 1) {
                setCountLastSearchTerm(valuefromfield?.length);
                console.log(
                    '\n\n\n\nCONTINUE TO MONITOR THIS - resetting add or update search results to empty array - maybe should not do that'
                );
                dispatch(addOrUpdateSearchResults([]));
            }
        }
    };
    const placeholderText = 'Enter name, email, phone # or userid';
    const pht = translate({
        id: 'client-search-placeholder',
        msg: placeholderText,
        usedefault: true,
    });
    const nooptionstext =
        'No clients were found. You can create a new account for them in the section below.';
    return (
        <Autocomplete
            // id="combo-box-demo"
            autoComplete
            id={`autocomplete-${idtouse}-${Math.random()}`}
            // options={recents}
            options={searchResults}
            filterOptions={filterOptions}
            name="clientSearch"
            clearOnEscape
            noOptionsText={nooptionstext}
            onInputChange={(event, value) => {
                setSearchTerm(value);
                testResetOfSearch(value);
                return value;
            }}
            getOptionLabel={({ label }) => {
                const optiontoDisplay = `${label}`;
                return optiontoDisplay;
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            filterSelectedOptions
            renderOption={(props, option) => {
                const htmlForList = renderSuggestion(option);
                return (
                    <li {...props} key={`${option.id}-${Math.random()}`}>
                        {htmlForList}
                    </li>
                );
            }}
            onChange={(event, value) => setRecentOptions(event, value)}
            sx={{ py: 0 }}
            renderInput={(params) => (
                <OutlineInputStyle placeholder={pht} {...params} />
            )}
        />
    );
}
