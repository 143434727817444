import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function sendConfirmationEmail(datareceived) {
    const url = getApiUrl('/send-appt-email-confirmation');

    try {
        const res = await axios.post(url, { data: datareceived });

        return res.data;
    } catch (err) {
        console.log('error in sendConfirmationEmail', err);
        return {
            success: false,
            error: err,
            msg: 'Error in sending confirmation email',
        };
    }
}
