import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function addUserToAppt(apptToConfirm) {
    const url = getApiUrl('/appts/complete-unpaid-appt');
    let returndata;
    await axios
        .post(url, { apptToConfirm })
        .then((res) => {
            console.log('after adding user to appt', res.data);
            const { data } = res;
            returndata = data;
        })
        .catch((error) => {
            console.log('the error is', error);
            returndata = error;
        });
    return returndata;
}
