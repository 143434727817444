// material-ui
import { Button, Grid } from '@mui/material';
import PlusIcon from 'assets/icons/plusIconRegular';

// ==============================|| ADD ANOTHER ITEM BUTTONS ||============================== //

const AddAnotherItemButton = ({ submitAction, textToDisplay }) => {
    return (
        <Grid container justifyContent="flex-end">
            <Grid item border={0} id="add-another-item-btn">
                <Button
                    variant="textButtonSecondary"
                    onClick={() => submitAction()}
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        px: 1,
                        py: 0,
                        height: '18px',
                        fontSize: '12px',
                        fontWeight: 200,
                        flexDirection: 'row',
                    }}
                    startIcon={<PlusIcon shrinkage={6} fontWeight={600} />}
                >
                    {textToDisplay}
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddAnotherItemButton;
