import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui

import {
    Button,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Collapse,
    Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import deleteEventFromSchedule from 'actions/schedule/deleteEventFromSchedule';
import setAlert from 'actions/status/setAlert';

import ApptFields from './ApptFields';
import ClassFields from './ClassFields';

import { useSelector } from 'store';

import { capitalizeString } from 'helpers';

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

const AddEventFrom = ({
    event,
    // range,
    handleDelete,
    // handleCreate,
    // handleUpdate,
    onCancel,
    initialStartDate,
    initialStartTime,
    initialEndTime,
    setUpdateAllEvents,
    setNotifyClientsModalOpen,
    setAttendeesAffected,
    typeOfEvent,
    refreshCalendar,
    setShowAlertWithEmailSms,
    setEventIdEditing,
}) => {
    const [title, setTitle] = useState('New ');
    const { config, studioConfig } = useSelector((state) => state.dibsstudio);
    const { instructorAltName, instructorPref } = studioConfig;
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const { dibsStudioId } = config;
    const [eventType, setEventType] = useState();
    const [actionTaking, setActionTaking] = useState('creating');

    const [open, setOpen] = useState(false);
    const [isBlockingTime, setIsBlockingTime] = useState(false);
    const [deleteApptText, setDeleteApptText] = useState(
        'Are you sure you want to delete this appointment?'
    );

    useEffect(() => {
        if (event) {
            if (dibsStudioId === '226' || dibsStudioId === '260')
                setTitle('Edit Appointment');
            else setTitle('Edit Class');
            setActionTaking('editing');
        } else {
            if (dibsStudioId === '226' || dibsStudioId === '260')
                setTitle('New Appointment');
            else setTitle('New Class');
            setActionTaking('creating');
        }
        if (typeOfEvent === 'block-time') {
            setIsBlockingTime(true);
            setTitle(
                `Block Time For ${capitalizeString(instructorPref)} ${capitalizeString(instructorAltName)}`
            );
            setDeleteApptText(
                'Are you sure you want to remove this blocked time?'
            );
            if (event) setActionTaking('editing');
        }
    }, [dibsStudioId, event, instructorAltName, instructorPref, typeOfEvent]);

    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260')
            setEventType('appt');
        else setEventType('class');
    }, [dibsStudioId]);

    // TO DO - make a switch to change from appt to class
    const deleteEvent = () => {
        // handleDelete(event.eventid);
        setOpen(true);
    };

    const doTheActualDelete = async () => {
        setOpen(false);
        handleDelete(event.eventid);
        // after done
        await deleteEventFromSchedule({
            eventid: event.eventid,
            studioEmployeeId,
            dibsStudioId,
        })
            .then((res) => {
                const { attendeesAffected } = res;

                if (attendeesAffected?.length > 0) {
                    const attendeestosend = attendeesAffected.map(
                        (attendee) => {
                            return {
                                ...attendee,
                                shouldSendEmail: true,
                                emailIsChecked: true,
                                shouldSendSms: true,
                                smsIsChecked: true,
                                smsIsAvailable: true, // don't have this accessible right now, but eventually will have it
                            };
                        }
                    );

                    setAttendeesAffected(attendeestosend);
                    setNotifyClientsModalOpen(true);
                } else {
                    setAlert({
                        msg: 'Successfully removed from schedule!',
                        seconds: 5,
                        success: true,
                    });
                }
                setUpdateAllEvents(true);
            })
            .catch((err) => {
                console.log('error deleting event from schedule', err);
                setAlert({
                    msg: 'There was an error deleting the appointment. Please refresh your screen and try again.',
                    seconds: 5,
                    error: true,
                });
            });
    };
    const collapseAlert = () => {
        return (
            <>
                <Grid
                    item
                    id={`alert-add-class-status-${event?.eventid || 'new'}`}
                    xs={12}
                >
                    <Collapse in={open}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {deleteApptText}
                            <Grid container spacing={1} sx={{ mt: 0.5 }}>
                                <Grid item id="no-button-no-delete">
                                    <Button
                                        variant="deleteEventCancel"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        No
                                    </Button>
                                </Grid>
                                <Grid item id="yes-delete-appt">
                                    <Button
                                        variant="deleteEvent"
                                        onClick={() => {
                                            setOpen(false);
                                            doTheActualDelete();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Collapse>
                </Grid>
            </>
        );
    };

    return (
        <Grid container justifyContent="center" id="new-appt-container">
            {collapseAlert()}
            <DialogTitle>{title}</DialogTitle>
            {!open && actionTaking !== 'creating' && (
                <IconButton
                    aria-label="close"
                    onClick={deleteEvent}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <DeleteForeverIcon />
                </IconButton>
            )}
            <DialogContent>
                {eventType === 'appt' && (
                    <ApptFields
                        onCancel={onCancel}
                        initialStartDate={initialStartDate}
                        initialStartTime={initialStartTime}
                        initialEndTime={initialEndTime}
                        selectedEvent={event}
                        actionTaking={actionTaking}
                        setUpdateAllEvents={setUpdateAllEvents}
                        isBlockingTime={isBlockingTime}
                        refreshCalendar={refreshCalendar}
                        setAttendeesAffected={setAttendeesAffected}
                        setShowAlertWithEmailSms={setShowAlertWithEmailSms}
                        setEventIdEditing={setEventIdEditing}
                        eventType={eventType}
                    />
                )}
                {eventType === 'class' && (
                    <ClassFields
                        onCancel={onCancel}
                        initialStartDate={initialStartDate}
                        initialStartTime={initialStartTime}
                        initialEndTime={initialEndTime}
                        selectedEvent={event}
                        actionTaking={actionTaking}
                        setUpdateAllEvents={setUpdateAllEvents}
                        isBlockingTime={isBlockingTime}
                        refreshCalendar={refreshCalendar}
                        setAttendeesAffected={setAttendeesAffected}
                        setShowAlertWithEmailSms={setShowAlertWithEmailSms}
                        setEventIdEditing={setEventIdEditing}
                        eventType={eventType}
                    />
                )}
            </DialogContent>
        </Grid>
    );
};

AddEventFrom.propTypes = {
    event: PropTypes.object,
    range: PropTypes.object,
    handleDelete: PropTypes.func,
    handleCreate: PropTypes.func,
    handleUpdate: PropTypes.func,
    onCancel: PropTypes.func,
};

export default AddEventFrom;
