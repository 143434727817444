import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    term: '',
    results: {
        recents: [],
        matches: [],
    },
};

const clientsearch = createSlice({
    name: 'clientsearch',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.term = action.payload;
        },
        clearSearchTerms: (state) => {
            state.term = '';
        },

        addOrUpdateSearchResults: (state, action) => {
            state.results.matches = action.payload;
        },
        addToRecentsSearch: (state, action) => {
            // const existingArray = [...state.results.recents];
            const existingArray = state.results.recents;

            // Check if the object with the same key or id already exists
            const updatedArray = [...existingArray, action.payload];
            const uniqueArray = updatedArray.filter(
                (item, index, self) =>
                    index ===
                    self.findIndex(
                        (t) => t.key === item.key || t.id === item.id
                    )
            );

            return {
                ...state,
                results: {
                    ...state.results,
                    recents: uniqueArray,
                },
            };
        },
        clearSearchResults: (state) => {
            state.results.matches = {};
        },
    },
});

export default clientsearch.reducer;
export const {
    setSearchTerm,
    clearSearchTerms,
    addOrUpdateSearchResults,
    clearSearchResults,
    addToRecentsSearch,
} = clientsearch.actions;
