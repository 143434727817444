// third-party
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// project imports
import axios from 'axios';
import { getApiUrl } from 'helpers';
import { dispatch } from '../index';

import { capitalizeFirstLetter } from 'helpers/general';

dayjs.extend(utc);
dayjs.extend(timezone);

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    events: [],
    showAvailabilityStatus: false,
    preferredView: '',
};

const calendar = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setShowAvailabilityStatus(state, action) {
            state.showAvailabilityStatus = action.payload;
        },
        setPreferredView(state, action) {
            state.preferredView = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            // console.log('in events success payload is\n\n', action.payload);
            state.events = action.payload;
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            state.events = action.payload;
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            state.events = action.payload;
        },

        // REMOVE EVENT
        removeEventSuccess(state, action) {
            state.events = action.payload;
        },
    },
});

// Reducer
export default calendar.reducer;

// ----------------------------------------------------------------------

// const transformToTimezone = (initialDate, timeZone) => {
//     console.log('\n\n\n\ninitial date is', initialDate);
//     const utcDate = dayjs.utc(initialDate).format('YYYY-MM-DDTHH:mm:ssZ');
//     console.log('utcDate is', utcDate);
//     const utcHour = dayjs.utc(initialDate).format('HH');
//     const utcMinute = dayjs.utc(initialDate).format('mm');
//     const utcDateJustDay = dayjs.utc(initialDate).format('YYYY-MM-DD');
//     console.log('utcHour is', utcHour);
//     console.log('utcMinute is', utcMinute);
//     console.log('utcDateJustDay is', utcDateJustDay);
//     const utchourString = utcHour.toString();
//     const utcMinuteString = utcMinute.toString();
//     const utcDateJustDayString = utcDateJustDay.toString();
//     const fullString = `${utcDateJustDayString} ${utchourString}:${utcMinuteString}`;
//     const newstringWithTImeZone = dayjs.tz(fullString, timeZone).format('YYYY-MM-DD HH:mm');
//     return newstringWithTImeZone;
// };

export function getEvents(
    dibsStudioId,
    timeZone,
    date,
    currentMinDate,
    currentMaxDate,
    expandwindow
) {
    return async () => {
        try {
            const url = getApiUrl('/studio/calendar/events');
            const response = await axios.post(url, {
                dibsid: dibsStudioId,
                dateoffocus: date,
                currentMinDate, // figure out what to do w these
                currentMaxDate, // figure out what to do w these
                expandwindow,
            });
            // const response = await axios.get('/api/calendar/events');
            const { data } = response;
            const { classEvents } = data;
            await classEvents.map((event) => {
                // const eventstartdate = transformToTimezone(event.start_date, timeZone);
                // const eventEndDate = transformToTimezone(event.end_date, timeZone);
                const { title } = event;
                event.apptTitle = title;
                if (event.is_blocked_time) {
                    event.title = `Blocked Time for ${capitalizeFirstLetter(event.instructor.firstname)}`;
                } else {
                    event.title = `${event.title} w/ ${capitalizeFirstLetter(event.instructor.firstname)}\n (${event.spots_booked} booked)`;
                }
                event.dbDescription = event.description;
                event.description = `${event.spots_booked} spots booked`;
                event.start = moment
                    .utc(event.start_date)
                    .format('YYYY-MM-DDTHH:mm:ssZ');
                // event.start = eventstartdate;
                event.end = moment
                    .utc(event.end_date)
                    .format('YYYY-MM-DDTHH:mm:ssZ');
                // event.end = eventEndDate;
                if (event.private) {
                    event.backgroundColor = '#d3e2d5';
                }
                if (event.spots_booked > 0) {
                    event.backgroundColor = '#b3bfcb';
                    if (event.spots_booked > 6) {
                        event.backgroundColor = '#95a3b0';
                    }
                }
                if (event.is_blocked_time) {
                    event.backgroundColor = '#f0f0f0';
                }
                event.allDay = false;
                // event.start = moment.utc(event.start_date).utcOffset(0, true).format();

                return event;
            });
            // newEvents();
            dispatch(calendar.actions.getEventsSuccess(classEvents));
        } catch (error) {
            console.log(`CALENDAR error is: ${JSON.stringify(error)}`);
            dispatch(calendar.actions.hasError(error));
        }
    };
}

export function addEvent(event) {
    return async () => {
        try {
            const url = getApiUrl('/calendar/events/new');
            const response = await axios.post(url, event);
            dispatch(calendar.actions.addEventSuccess(response.data));
        } catch (error) {
            dispatch(calendar.actions.hasError(error));
        }
    };
}

export function updateEvent(event) {
    // return async () => {
    //     try {
    //         const url = getApiUrl('/calendar/events/update');
    //         console.log('line 160 --- updating this event', event);
    //         const response = await axios.post(url, event);
    //         console.log('response from line 160 is', response);
    //         dispatch(calendar.actions.updateEventSuccess(response.data.events));
    //     } catch (error) {
    //         console.log(
    //             'there is an error in the updateEvent dispatch from calendar.js in the store',
    //             error
    //         );
    //         dispatch(calendar.actions.hasError(error));
    //     }
    // };
    console.log('does everything still work even if this is nothing?');
}
export function updateAppt(appt) {
    return async () => {
        try {
            const url = getApiUrl('/appts/update - info');
            const response = await axios.post(url, appt);
            dispatch(calendar.actions.updateEventSuccess(response.data.events));
        } catch (error) {
            dispatch(calendar.actions.hasError(error));
        }
    };
}

export function removeEvent(eventId) {
    return async () => {
        try {
            const response = await axios.post(
                getApiUrl('/calendar/events/remove'),
                {
                    eventId,
                }
            );
            dispatch(calendar.actions.removeEventSuccess(response.data));
        } catch (error) {
            dispatch(calendar.actions.hasError(error));
        }
    };
}
export const { setShowAvailabilityStatus, setPreferredView } = calendar.actions;
