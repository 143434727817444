import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#fff',
        color: '#fff',
        borderRadius: '12px',
        padding: '10px',
    },
}));
const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: '#e7b2a5',
    fontSize: '1.25rem',
    fontWeight: '300',
    padding: '2px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    // padding: theme.spacing(1),
    // backgroundColor: '#424242',
    color: '#666',
    borderRadius: '8px',
    padding: '0px 8px',
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
}));
const CustomListItem = styled(ListItem)(({ theme }) => ({
    padding: '6px 8px',
    alignItems: 'flex-start',
}));

function ChangesDialog({ open, handleClose, changes, handleRefresh }) {
    return (
        <CustomDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="changes-dialog-title"
        >
            <CustomDialogTitle id="changes-dialog-title">
                What's New
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        color: '#e7b2a5',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </CustomDialogTitle>
            <CustomDialogContent>
                <List sx={{ pt: '5px' }}>
                    {changes.map((change, index) => (
                        <CustomListItem key={index}>
                            <ListItemIcon
                                sx={{ minWidth: '20px', marginTop: '8px' }}
                            >
                                <FiberManualRecordIcon
                                    sx={{
                                        fontSize: '0.6rem',
                                        color: '#e7b2a5',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText primary={change} />
                        </CustomListItem>
                    ))}
                </List>
            </CustomDialogContent>
            <CustomDialogActions>
                {/* <Button
                    onClick={handleClose}
                    // color="primary"
                    variant="buttonInverse"
                >
                    Close
                </Button> */}
                <Button onClick={handleRefresh} color="primary" fullWidth>
                    Refresh
                </Button>
            </CustomDialogActions>
        </CustomDialog>
    );
}

export default ChangesDialog;
