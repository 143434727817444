import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
dayjs.extend(utc);

export default function BasicTimePicker({
    label,
    defaultTimeValue,
    value,
    changeAction,
    keytouse,
    keylabel,
    acceptAction,
}) {
    let keyfortimepicker = `timepicker-${keylabel}`;
    if (keytouse) {
        keyfortimepicker = keytouse;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                key={keyfortimepicker}
                id={keyfortimepicker}
                label={label}
                defaultValue={defaultTimeValue}
                value={value}
                timezone="UTC"
                name={keyfortimepicker}
                slotProps={{
                    textField: {
                        size: 'small',
                        // variant: 'filled',
                        sx: { fontSize: '10px', paddingRight: '0px' },
                    },
                }}
                // onAccept={acceptAction}
                onChange={(e) => {
                    changeAction(e, keylabel);
                }}
            />
        </LocalizationProvider>
    );
}
