import { useEffect, useState } from 'react';
import { Grid, Divider, Button, Typography, Fade } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import AutocompleteField from 'components/search/AutoCompleteClientSearchShort';
import AutocompleteFieldUI from 'ui-component/textfields/Autocomplete';
import DatePicker from 'ui-component/pickers/DatePicker';
import TimePicker from 'ui-component/pickers/TimePicker';
import BookedClients from 'components/schedule-sections/bookedClients';
import BookedClientsPotentially from 'components/schedule-sections/potentiallyAssignedClients';
import NewClientAccount from 'components/clients/createClient';
import bookFromSchedule from 'actions/checkout/bookFromSchedule';

import createNewAppt from 'actions/appointments/createNewAppt';
import updateExistingClass from 'actions/schedule/updateExistingClass';
import addUserToAppt from 'actions/appointments/addUserToAppt';
import addUserToApptAndSendEmail from 'actions/appointments/addUserToApptAndSendEmail';
import setAlert from 'actions/status/setAlert';
import sendConfirmationEmail from 'actions/emails/send-confirmation-appt';
import sendSmsConfirmation from 'actions/emails/send-sms-confirmation-appt';
import getAttendees from 'actions/schedule/getAttendees';
import { getEvents } from 'store/slices/calendar';
import AddAnotherClientButton from 'components/clients/addAnotherClientToEventButton';
import EmailAndSmsSwitches from 'components/notifications/EmailAndSmsSwitches';

import BookFromCalendar from 'components/booking-checkout/fromCalendar';
import { useSelector, dispatch } from 'store';
import { capitalizeString } from 'helpers';

dayjs.extend(utc);

function compareByName(a, b) {
    return a.label.localeCompare(b.label);
}

const ClassFields = ({
    onCancel,
    initialStartDate,
    initialStartTime,
    initialEndTime,
    selectedEvent,
    actionTaking,
    setUpdateAllEvents,
    isBlockingTime,
    refreshCalendar,
    setAttendeesAffected,
    setShowAlertWithEmailSms,
    setEventIdEditing,
    eventType,
}) => {
    const theme = useTheme();
    const { apptTypes } = useSelector((state) => state.appointments);
    const { instructorData } = useSelector((state) => state.instructors);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { instructorAltName, instructorPref } = useSelector(
        (state) => state.dibsstudio.studioConfig
    );
    const [instructorTitle, setInstructorTitle] = useState('Name');

    useEffect(() => {
        if (instructorAltName) {
            setInstructorTitle(`${capitalizeString(instructorAltName)} Name`);
        } else {
            setInstructorTitle('Name');
        }
    }, [instructorAltName]);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const { timeZone, studioName } = useSelector(
        (state) => state.dibsstudio.config
    );
    const { customerServicePhone, twilioCustomNumUS } = useSelector(
        (state) => state.dibsstudio.customerService
    );
    const [selectionOptions, setSelectionOptions] = useState([]);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [apptTypeSelected, setApptTypeSelected] = useState(null);
    const [instructorSelected, setInstructorSelected] = useState(null);
    const [endTimeToPass, setEndTimeToPass] = useState(initialEndTime);
    const [buttonText, setButtonText] = useState('Create Appointment');

    const [isAddingNewClient, setIsAddingNewClient] = useState(false);
    const [paymentMethodToUse, setPaymentMethodToUse] = useState(null);
    const [bookNowText, setBookNowText] = useState('Book Now');
    const [amountWillBeCharged, setAmountWillBeCharged] = useState(0);
    const [willApplyPass, setWillApplyPassParent] = useState(false);
    const [passIdToUse, setPassIdToUseParent] = useState(null);
    const [taxAmount, setTaxAmount] = useState(null);
    const [subPriceToCharge, setSubPriceToCharge] = useState(null);
    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
    const [showAutoCompleteClient, setShowAutoCompleteClient] = useState(true);
    const [addingMoreClientsToEvent, setAddingMoreClientsToEvent] =
        useState(false);
    const [showBookedClients, setShowBookedClients] = useState(false);
    const [showBookingPaymentPanel, setShowBookingPaymentPanel] =
        useState(false);

    const [startTimeToPass, setStartTimeToPass] = useState(initialStartTime);
    const [minutesToAdd, setMinutesToAdd] = useState(30);
    const [manuallySetEnd, setManuallySetEnd] = useState(false);
    const [clientSelected, setClientSelected] = useState(null);

    const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true);
    const [sendSMSConfirmation, setSendSMSConfirmation] = useState(true);
    const [smsLabel, setSMSLabel] = useState('Send SMS/Text Confirmation');
    const [emailLabel, setEmailLabel] = useState('Send Email Confirmation');
    const [attendeeForThisEvent, setAttendeeForThisEvent] = useState([]);
    const [hideSaveButtons, setHideSaveButtons] = useState(false);
    const [eventidSelected, setEventidSelected] = useState(null);

    const [updateEvents, setUpdateEvents] = useState(false);
    const [itemsChanged, setItemsChanged] = useState([]);
    const [dateToPass, setDateToPass] = useState(initialStartDate);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        return () => {
            setIsSubmitting(false);
        };
    }, []);
    useEffect(() => {
        if (isBlockingTime) {
            setButtonText('Save Blocked Time');
            setManuallySetEnd(true);
        }
    }, [isBlockingTime]);
    useEffect(() => {
        if (!isBlockingTime) {
            if (actionTaking === 'editing') {
                setButtonText('Update Class');
            } else {
                setButtonText('Create Class');
            }
        }
    }, [actionTaking, isBlockingTime]);

    useEffect(() => {
        const getAttendeeInfo = async () => {
            await getAttendees(selectedEvent.eventid)
                .then((res) => {
                    const { attendees } = res;
                    if (attendees?.length > 0) {
                        setAttendeeForThisEvent(attendees);
                        setShowBookedClients(true);
                        setAddingMoreClientsToEvent(false);
                    } else {
                        setAttendeeForThisEvent([]);
                        setShowBookedClients(false);
                        setAddingMoreClientsToEvent(false);
                    }
                })
                .catch((error) => {
                    console.log('error receievd was', error);
                });
        };
        if (selectedEvent) {
            setApptTypeSelected({
                label: selectedEvent.apptTitle,
                apptdefaultprice: selectedEvent.price_dibs,
                eventid: selectedEvent.eventid,
            });
            const { instructor } = selectedEvent;
            if (instructor) {
                const lname = instructor.lastname;
                let fullname;
                if (lname?.length > 1) {
                    fullname = `${instructor.firstname} ${lname}`;
                } else {
                    fullname = instructor.firstname;
                }
                const newinfo = {
                    value: instructor.id,
                    label: fullname,
                };
                setInstructorSelected(newinfo);
            }
            getAttendeeInfo();
            setEventidSelected(selectedEvent.eventid);
        }
        if (updateEvents) {
            getAttendeeInfo();
            setUpdateEvents(false);
        }
    }, [selectedEvent, updateEvents]);

    useEffect(() => {
        if (apptTypes?.length > 0) {
            const options = apptTypes.map((appt) => {
                return {
                    label: appt.appointment_type,
                    value: appt.id,
                    minutes: appt?.length_minutes,
                    apptdefaultprice: appt.default_price,
                };
            });
            const optionsSorted = options.sort(compareByName);
            setSelectionOptions(optionsSorted);
        }
    }, [apptTypes]);
    useEffect(() => {
        if (instructorData?.length > 0) {
            const options = instructorData.map((i) => {
                const lname = i.lastname;
                let fullname;
                if (lname?.length > 1) {
                    fullname = `${i.firstname} ${lname}`;
                } else {
                    fullname = i.firstname;
                }
                return {
                    label: fullname,
                    value: i.id,
                };
            });
            const optionsSorted = options.sort(compareByName);
            setInstructorOptions(optionsSorted);
        }
    }, [instructorData]);
    useEffect(() => {
        if (!manuallySetEnd && !selectedEvent) {
            const baseForEndTime = dayjs.utc(startTimeToPass);
            const newEndTime = baseForEndTime.add(minutesToAdd, 'minutes');
            setEndTimeToPass(newEndTime);
        }
    }, [
        apptTypeSelected,
        manuallySetEnd,
        minutesToAdd,
        selectedEvent,
        startTimeToPass,
    ]);
    const handleCancel = () => {
        onCancel();
        setPaymentMethodToUse(null);
    };
    const handleApptSelection = (event, value) => {
        if (value) {
            setApptTypeSelected(value);
            setMinutesToAdd(value.minutes);
        }
        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'apptType']);
        }
    };
    const handleStartTimeChange = (event) => {
        setStartTimeToPass(event);
        setManuallySetEnd(false);
        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'time']);
        }
    };
    const handleDateChange = (event) => {
        const newdate = dayjs.utc(event);
        const newdateformatted = newdate.format('M/D/YYYY');
        setDateToPass(newdateformatted);
        const hourEnd = dayjs.utc(endTimeToPass).hour();
        const minuteEnd = dayjs.utc(endTimeToPass).minute();
        const newEndTime = dayjs.utc(event).hour(hourEnd).minute(minuteEnd);
        setEndTimeToPass(newEndTime);
        const hourStart = dayjs.utc(startTimeToPass).hour();
        const minuteStart = dayjs.utc(startTimeToPass).minute();
        const newStartTime = dayjs
            .utc(event)
            .hour(hourStart)
            .minute(minuteStart);
        setStartTimeToPass(newStartTime);
        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'date']);
        }
    };
    const handleEndTimeChange = (event) => {
        setManuallySetEnd(true);
        setEndTimeToPass(event);
        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'time']);
        }
    };

    const handleInstructorSelection = (event, value) => {
        setInstructorSelected(value);
        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'instructor']);
        }
    };
    const handleChangeWasMade = () => {
        setUpdateAllEvents(true);
    };
    const handleClientSelection = (event, from) => {
        if (event) {
            setClientSelected(event);
            setShowBookingPaymentPanel(true);
        }
        if (from === 'fromAutoComplete') {
            setAddingMoreClientsToEvent(true);
            setHideSaveButtons(true);
        } else {
            setAddingMoreClientsToEvent(false);
            // setHideSaveButtons(false);
        }

        // if (actionTaking === 'editing') {
        //     setItemsChanged([...itemsChanged, 'client']);
        // }
    };
    const handleSubmit = async () => {
        // get the date updated to include the date
        if (!isBlockingTime) {
            if (!apptTypeSelected) {
                setAlert({
                    msg: 'Please select an appointment type before saving.',
                    seconds: 5,
                    warning: true,
                });
                return;
            }
        }

        if (!instructorSelected) {
            const msgtouse = `Please assign ${instructorPref} ${instructorAltName} to the ${isBlockingTime ? 'blocked time' : 'appointment'} before saving.`;
            setAlert({
                msg: msgtouse,
                seconds: 5,
                warning: true,
            });
            return;
        }

        const apptNametoUse = apptTypeSelected
            ? apptTypeSelected.label
            : `Blocked Time - ${instructorSelected.label}`;
        const apptdefaultpricetouse = apptTypeSelected
            ? apptTypeSelected.apptdefaultprice
            : 0;
        const apptIdtouse = apptTypeSelected ? apptTypeSelected.value : null;
        const apptminutestouse = apptTypeSelected
            ? apptTypeSelected.minutes
            : 10;
        // if (!apptNametoUse) apptNametoUse = 'Blocked Time';
        let apptToPass = {
            dibsStudioId,
            apptId: apptIdtouse,
            apptName: apptNametoUse,
            isBlockingTime,
            studioEmployeeId,
            instructorid: instructorSelected.value,
            instructorname: instructorSelected.label,
            apptLength: apptminutestouse,
            apptDefaultPrice: apptdefaultpricetouse,
            priceAppt: apptdefaultpricetouse,
            apptOverridePrice: apptdefaultpricetouse,
            originalStartTimeUnFormatted: startTimeToPass,
            mainTimezone: timeZone,
            studioName: studioName,
            startTimeToPass: dayjs
                .utc(startTimeToPass)
                .format('YYYY-MM-DD HH:mm:ss'),
            endTimeToPass: dayjs
                .utc(endTimeToPass)
                .format('YYYY-MM-DD HH:mm:ss'),
            clientid: clientSelected ? clientSelected.id : null,
            userid: clientSelected ? clientSelected.id : null,
            clientname: clientSelected ? clientSelected.label : null,
            clientemail: clientSelected ? clientSelected.email : null,
            clientfirstname: clientSelected ? clientSelected.firstName : null,
            clientlastname: clientSelected ? clientSelected.lastName : null,
            eventid: selectedEvent ? selectedEvent.eventid : null,
            mobilephone: clientSelected ? clientSelected.phone : null,
            itemsChanged,
            twilioCustomNumUS: twilioCustomNumUS,
            customerServicePhone: customerServicePhone
                ? customerServicePhone
                : null,
        };
        if (actionTaking === 'creating') {
            setIsSubmitting(true);
            await createNewAppt(apptToPass)
                .then(async (res) => {
                    const { success, instructorConflict, message } = res;
                    if (instructorConflict) {
                        if (
                            message ===
                            'Instructor is not available at this time'
                        ) {
                            setAlert({
                                msg: `${apptToPass.instructorname} is not available at this time. Please choose a different ${instructorAltName} or a different time. Click 'Show availability' to see the ${instructorAltName}'s schedule.`,
                                seconds: 10,
                                error: true,
                            });
                        } else {
                            setAlert({
                                msg: `There is a conflict with ${apptToPass.instructorname}'s schedule. Please choose a different ${instructorAltName} or a different time. Click 'Show availability' to see the ${instructorAltName}'s schedule.`,
                                seconds: 10,
                                error: true,
                            });
                        }

                        setIsSubmitting(false);
                        return;
                    }
                    if (success) {
                        // if there is a user - add the dibstransaction and user to attendees table
                        if (clientSelected) {
                            const { data } = res;
                            const { eventid } = data[0];
                            apptToPass = {
                                ...apptToPass,
                                eventid,
                                fromLocation: 'studio-admin',
                            };
                            const email = clientSelected.email;

                            await addUserToAppt(apptToPass)
                                .then(async (res) => {
                                    // setUpdateAllEvents(true);
                                    const { status } = res;
                                    if (status === 1) {
                                        if (sendEmailConfirmation) {
                                            // send email confirmation
                                            const datatosend = {
                                                appttocreate: {
                                                    apptToConfirm: apptToPass,
                                                    email,
                                                    dibsId: dibsStudioId,
                                                },
                                                eventscreated: [
                                                    {
                                                        eventid,
                                                    },
                                                ],
                                            };
                                            await sendConfirmationEmail(
                                                datatosend
                                            ).catch((error) => {
                                                console.log(
                                                    'error sending email confirmation',
                                                    error
                                                );
                                                setAlert({
                                                    msg: 'Error sending the confirmation email. Please refresh your screen and try again.',
                                                    seconds: 5,
                                                    error: true,
                                                });
                                            });
                                            console.log(
                                                'sending an email confirmation for this appt'
                                            );
                                        }

                                        if (sendSMSConfirmation) {
                                            // send SMS confirmation
                                            const datatosend = {
                                                appttocreate: {
                                                    apptToConfirm: apptToPass,
                                                    dibsId: dibsStudioId,
                                                    eventtype: 'appt',
                                                },
                                                eventscreated: [
                                                    {
                                                        eventid,
                                                    },
                                                ],
                                            };
                                            await sendSmsConfirmation(
                                                datatosend
                                            )
                                                .then((res) => {
                                                    console.log(
                                                        'response from sending sms',
                                                        res
                                                    );
                                                    const { msg, code } = res;
                                                    if (code === 5) {
                                                        setAlert({
                                                            msg: msg,
                                                            seconds: 5,
                                                            warning: true,
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(
                                                        'error sending sms confirmation',
                                                        error
                                                    );
                                                    setAlert({
                                                        msg: 'Error sending the sms email. Please refresh your screen and try again.',
                                                        seconds: 5,
                                                        error: true,
                                                    });
                                                });
                                        }
                                        setIsSubmitting(false);
                                    }
                                })
                                .catch((error) => {
                                    console.log(
                                        'error adding user to appt is',
                                        error
                                    );
                                    setAlert({
                                        msg: 'Error adding the client to the appointment. Please refresh your screen and try again.',
                                        seconds: 5,
                                        error: true,
                                    });
                                    setIsSubmitting(false);
                                    return;
                                });
                        }
                        setAlert({
                            msg: `Successfully created the ${isBlockingTime ? 'blocked time' : 'appointment'}`,
                            seconds: 5,
                            success: true,
                        });
                        setIsSubmitting(false);
                        setUpdateEvents(true);
                        refreshCalendar();
                        dispatch(getEvents(dibsStudioId, timeZone));
                        onCancel(); // this just closes the modal. if we add more to this function, this needs to be changed. It comes from calendar. It is handleModalClose function.
                    } else {
                        setAlert({
                            msg: `Could not create the ${isBlockingTime ? 'blocked time' : 'appointment'}`,
                            seconds: 5,
                            error: true,
                        });
                        return;
                    }
                })
                .catch((error) => {
                    console.log('could not create the appt', error);
                });
        } else {
            if (apptToPass.itemsChanged?.length === 0) {
                onCancel();
                return;
            } else {
                console.log('class to pass is', apptToPass);
                await updateExistingClass(
                    apptToPass,
                    eventidSelected,
                    sendEmailConfirmation,
                    'apptfields'
                )
                    .then(async () => {
                        setUpdateAllEvents(true);

                        const attendeeList =
                            await getAttendees(eventidSelected);
                        const { attendees } = attendeeList;
                        const attendeeListNew = attendees.map((a) => {
                            let smsAvailable = false;
                            const { mobilephone } = a;
                            if (mobilephone) {
                                if (mobilephone?.length > 2) {
                                    smsAvailable = true;
                                }
                            }
                            return {
                                ...a,
                                emailIsChecked: true,
                                smsIsChecked: true,
                                shouldSendEmail: true,
                                shouldSendSms: true,
                                smsIsAvailable: smsAvailable,
                            };
                        });
                        if (attendeeListNew?.length > 0) {
                            setAttendeeForThisEvent(attendeeListNew);
                            setAttendeesAffected(attendeeListNew);
                            setEventIdEditing(eventidSelected);
                            setShowAlertWithEmailSms(true);
                        } else {
                            setAlert({
                                msg: 'Successfully updated the appointment',
                                seconds: 5,
                                success: true,
                            });
                        }

                        setUpdateEvents(true);
                        dispatch(getEvents(dibsStudioId, timeZone));
                        setItemsChanged([]);
                        onCancel(); // this just closes the modal. if we add more to this function, this needs to be changed. It comes from calendar. It is handleModalClose function.
                    })
                    .catch((error) => {
                        console.log('error updating the event', error);
                        setAlert({
                            msg: 'Could not update the appointment - class page',
                            seconds: 5,
                            error: true,
                        });
                    });
            }
        }
    };

    const handleBookNow = async () => {
        setIsSubmittingPayment(true);
        await bookFromSchedule(
            clientSelected?.id,
            eventidSelected,
            paymentMethodToUse,
            willApplyPass,
            passIdToUse,
            amountWillBeCharged,
            dibsStudioId,
            taxAmount,
            subPriceToCharge,
            studioEmployeeId,
            'studio-admin',
            eventType
        ).then((res) => {
            const { msg, msgToShow } = res;
            if (msg === 'success') {
                setBookNowText('Booked');
                setClientSelected(null);
                setAddingMoreClientsToEvent(false);
                setTimeout(() => {
                    setHideSaveButtons(false);
                    setShowBookedClients(true);
                }, 1500);
                setUpdateEvents(true);
                dispatch(getEvents(dibsStudioId, timeZone));
                setAlert({
                    msg: msgToShow,
                    seconds: 5,
                    success: true,
                });
            } else {
                setAlert({
                    msg: msgToShow,
                    seconds: 5,
                    error: true,
                });
            }
            setIsSubmittingPayment(false);
        });
    };
    const toggleInfo = `Use the toggle switches below to choose whether to send the client an email or sms confirmation.`;
    return (
        <div>
            <Grid container justifyContent="center">
                {!isBlockingTime && (
                    <Grid
                        item
                        xs={11}
                        md={9}
                        id="appt-type-item-add-event-class"
                        // border={2}
                        sx={{ p: 0, pt: 1, mb: 2 }}
                    >
                        <AutocompleteFieldUI
                            title="Appointment Type"
                            options={selectionOptions}
                            changeAction={handleApptSelection}
                            valueSelected={apptTypeSelected}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={11}
                    md={9}
                    id="instructor-name-item-add-event-class"
                    // border={2}
                    sx={{ p: 0, pt: 1, mb: 2 }}
                >
                    <AutocompleteFieldUI
                        title={instructorTitle}
                        options={instructorOptions}
                        changeAction={handleInstructorSelection}
                        valueSelected={instructorSelected}
                    />
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11}
                    md={9}
                    // border={2}
                    id="start-date-picker"
                    sx={{ p: 0, m: 0, mb: 2 }}
                >
                    <DatePicker
                        initialStartDate={initialStartDate}
                        changeAction={handleDateChange}
                        valueSelected={dateToPass}
                    />
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11}
                    // border={2}
                    md={9}
                    id="start-date-time-picker"
                    sx={{ p: 0, pt: 1, mb: 2 }}
                >
                    <Grid container>
                        <Grid item xs={5} sx={{ mr: 1 }}>
                            <Grid item xs={11} md={9}>
                                <TimePicker
                                    label="Start Time"
                                    value={startTimeToPass}
                                    defaultTimeValue={initialStartTime}
                                    changeAction={handleStartTimeChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid item xs={11} md={9}>
                                <TimePicker
                                    label="End Time"
                                    value={endTimeToPass}
                                    defaultTimeValue={initialEndTime}
                                    changeAction={handleEndTimeChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                {showBookedClients && (
                    <>
                        <Grid
                            item
                            xs={11}
                            md={9}
                            sx={{ p: 0, pt: 1, mb: 2 }}
                            id="booked-clients-grid-item-class"
                        >
                            <Typography>Booked Client(s)</Typography>
                            <BookedClients
                                attendees={attendeeForThisEvent}
                                setHideSaveButtons={setHideSaveButtons}
                                thiseventid={eventidSelected}
                                dibsId={dibsStudioId}
                                startTime={startTimeToPass}
                                setUpdateEvents={setUpdateEvents}
                                handleClientSelection={handleClientSelection}
                                eventType={eventType}
                                handleChangeWasMade={handleChangeWasMade}
                                setClientSelected={setClientSelected}
                            />
                        </Grid>
                        {!addingMoreClientsToEvent && !clientSelected && (
                            <Grid
                                xs={12}
                                md={9}
                                sx={{ p: 0, mb: 2 }}
                                item
                                id="grid-holding-add-another-client-button"
                            >
                                <Grid container justifyContent="flex-end">
                                    <AddAnotherClientButton
                                        thiseventid={eventidSelected}
                                        eventType={eventType}
                                        setAddingMoreClientsToEvent={
                                            setAddingMoreClientsToEvent
                                        }
                                        setShowBookedClients={
                                            setShowBookedClients
                                        }
                                        setHideSaveButtons={setHideSaveButtons}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
                {!showAutoCompleteClient && (
                    <Grid item xs={11} md={9} sx={{ p: 0, pt: 1, mb: 2 }}>
                        <Typography>Selected Client</Typography>
                        <BookedClientsPotentially
                            attendees={attendeeForThisEvent}
                            setHideSaveButtons={setHideSaveButtons}
                            thiseventid={eventidSelected}
                            dibsId={dibsStudioId}
                            startTime={startTimeToPass}
                            setUpdateEvents={setUpdateEvents}
                            setAttendeeForThisEvent={setAttendeeForThisEvent}
                            setShowAutoCompleteClient={
                                setShowAutoCompleteClient
                            }
                            setClientSelected={setClientSelected}
                        />
                    </Grid>
                )}
                {isAddingNewClient && (
                    <>
                        <Grid
                            item
                            xs={11.5}
                            border={0}
                            md={9.5}
                            sx={{ px: 0.85 }}
                        >
                            <NewClientAccount
                                setHideSaveButtons={setHideSaveButtons}
                                setIsAddingNewClient={setIsAddingNewClient}
                                handleClientSelection={handleClientSelection}
                                setAttendeeForThisEvent={
                                    setAttendeeForThisEvent
                                }
                                setShowAutoCompleteClient={
                                    setShowAutoCompleteClient
                                }
                            />
                        </Grid>
                        <Grid item xs={11.5} md={9.5}>
                            <Divider sx={{ mt: 1, mb: 2 }} />
                        </Grid>
                    </>
                )}
                {!showBookedClients &&
                    !isBlockingTime &&
                    !isAddingNewClient &&
                    showAutoCompleteClient && (
                        <>
                            <Grid
                                item
                                xs={11}
                                md={9}
                                sx={{ p: 0, pt: 1, mb: 2 }}
                            >
                                {addingMoreClientsToEvent && (
                                    <Typography sx={{ mb: 2.5 }}>
                                        Add Another Client To This Class
                                    </Typography>
                                )}
                                <AutocompleteField
                                    title={
                                        addingMoreClientsToEvent
                                            ? 'Select Client...'
                                            : 'Add A Client (Optional)'
                                    }
                                    idtoadd="class-client-search"
                                    onChangeAction={handleClientSelection}
                                    setIsAddingNewClient={setIsAddingNewClient}
                                    clientSelected={clientSelected}
                                />
                            </Grid>
                            {showBookingPaymentPanel && (
                                <Grid item xs={11} md={9}>
                                    <BookFromCalendar
                                        eventidSelected={eventidSelected}
                                        clientSelected={clientSelected}
                                        eventType={eventType}
                                        dibsStudioId={dibsStudioId}
                                        setPaymentMethodToUseParent={
                                            setPaymentMethodToUse
                                        }
                                        setWillApplyPassParent={
                                            setWillApplyPassParent
                                        }
                                        setPassIdToUseParent={
                                            setPassIdToUseParent
                                        }
                                        setAmountWillBeCharged={
                                            setAmountWillBeCharged
                                        }
                                        setTaxAmount={setTaxAmount}
                                        setSubPriceToCharge={
                                            setSubPriceToCharge
                                        }
                                    />
                                </Grid>
                            )}
                            {addingMoreClientsToEvent && (
                                <Grid item xs={11} md={9}>
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            id="cancel-add-more-clients-button"
                                            variant="cancel"
                                            sx={{
                                                mt: 1,
                                                mb: 2,
                                                mr: showBookingPaymentPanel
                                                    ? 2
                                                    : 0,
                                            }}
                                            onClick={() => {
                                                setAddingMoreClientsToEvent(
                                                    false
                                                );
                                                setHideSaveButtons(false);
                                                setShowBookedClients(true);
                                                setClientSelected(null);
                                                setPaymentMethodToUse(null);
                                                setIsSubmittingPayment(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {showBookingPaymentPanel && (
                                            <LoadingButton
                                                disableElevation
                                                loading={isSubmittingPayment}
                                                variant="greenconfirm"
                                                color="primary"
                                                id="book-now-button-loading"
                                                onClick={handleBookNow}
                                                sx={{
                                                    bgcolor:
                                                        theme.palette
                                                            .globalcolors
                                                            .submit,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            theme.palette
                                                                .globalcolors
                                                                .hoverSubmit,
                                                    },
                                                    mt: 1,
                                                    mb: 2,
                                                }}
                                            >
                                                {bookNowText}
                                            </LoadingButton>
                                        )}
                                    </Grid>
                                    <Grid item sx={{ mb: 2, mt: 1 }}>
                                        <Typography variant="h8">
                                            {toggleInfo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}

                {Boolean(clientSelected) && (
                    <>
                        <Grid item xs={11.5} md={9.5}>
                            <Divider sx={{ mt: 1, mb: 2 }} />
                        </Grid>
                        <EmailAndSmsSwitches
                            sendEmailConfirmation={sendEmailConfirmation}
                            sendSMSConfirmation={sendSMSConfirmation}
                            setSMSLabel={setSMSLabel}
                            smsLabel={smsLabel}
                            setShowBookedClients={setShowBookedClients}
                            clientSelected={clientSelected}
                            setSendEmailConfirmation={setSendEmailConfirmation}
                            setEmailLabel={setEmailLabel}
                            emailLabel={emailLabel}
                            setSendSMSConfirmation={setSendSMSConfirmation}
                        />
                    </>
                )}

                {!hideSaveButtons && !isAddingNewClient && (
                    <Grid item xs={11.5} md={9.5}>
                        <Grid container justifyContent="flex-end">
                            <Grid item sx={{ mr: 2 }}>
                                <Button
                                    id="cancel-appt-button-fields"
                                    variant="cancel"
                                    sx={{ mt: 1, mb: 2 }}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item sx={{ mr: 1 }}>
                                <LoadingButton
                                    disableElevation
                                    loading={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    id="save-class-button-loading"
                                    onClick={handleSubmit}
                                    sx={{
                                        bgcolor:
                                            theme.palette.globalcolors.submit,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.globalcolors
                                                    .hoverSubmit,
                                        },
                                        mt: 1,
                                        mb: 2,
                                    }}
                                >
                                    {buttonText}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ClassFields;
