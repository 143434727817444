import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiUrl } from 'helpers';

import { dispatch } from './../index';

const initialState = {
    apptsBeingConfirmed: [],
    billing: null,
    cardbrand: null,
    cardtocharge: null,
    cartPreparedForCheckout: [],
    checkout: {
        discount: 0,
        products: [],
        total: 0,
    },
    creditApplied: 0,
    error: null,
    expy: null,
    isPurchasingAPackage: false,
    itemsInCart: [],
    lastfour: null,
    cardlastfour: null,
    needstopay: true,
    packageItemsInCart: [],
    payment: {
        method: 'card',
        type: 'free',
    },
    purchasingPackageId: null,
    step: 0,
    subtotal: 0,
    tax: 0,
    taxrateforclasses: 0.045,
    taxrateforretail: 0,
    total: 0,
    totalBeforeCreditApplied: 0,
    totalAfterCreditApplied: 0,
    totalToChargeAfterCreditAndDiscounts: 0,
};

function roundTo(n, digits) {
    if (digits === undefined) {
        digits = 0;
    }

    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    return Math.round(n) / multiplicator;
}

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setDiscountSuccess(state, action) {
            let difference = 0;
            if (state.checkout.discount > 0) {
                difference = state.checkout.discount;
            }

            state.checkout.discount = action.payload.amount;
            state.checkout.total =
                state.checkout.total + difference - action.payload.amount;
        },
        setTotal(state, action) {
            return {
                ...state,
                total: action.payload,
            };
        },
        setApptsBeingConfirmed(state, action) {
            return {
                ...state,
                apptsBeingConfirmed: action.payload,
            };
        },
        setTotalBeforeCreditApplied(state, action) {
            return {
                ...state,
                totalBeforeCreditApplied: action.payload,
                total: action.payload,
            };
        },
        setSubTotal(state, action) {
            return {
                ...state,
                subtotal: action.payload,
            };
        },
        setDiscountedPackageInfo(state, action) {
            const { packprice, packtax, packcredit, packtotal } =
                action.payload;
            return {
                ...state,
                discountedpackage: {
                    packageprice: packprice,
                    packagetax: packtax,
                    packagetotalprice: packtotal,
                    packagecredit: packcredit,
                },
            };
        },
        setTotalTax(state, action) {
            const newtax = roundTo(action.payload, 2);
            return {
                ...state,
                tax: newtax,
            };
        },
        setNeedsToPay(state, action) {
            return {
                ...state,
                needstopay: action.payload,
            };
        },
        updateQuantityInCart(state, action) {
            const eventinfo = action.payload;
            const { eventid, updatedQuantity } = eventinfo;

            const indexOfPackage = state.itemsInCart.findIndex(
                (i) => i.eventid === eventid
            );
            // const currentPackage = {
            //     ...state.packageItemsInCart[indexOfPackage],
            //     quantity: updatedQuantity,
            // };
            // console.log(
            //     `currentPackage to set is: ${JSON.stringify(currentPackage)}`,
            // );
            state.itemsInCart[indexOfPackage].quantity = updatedQuantity;
        },
        updatePackageQuantityInCart(state, action) {
            const packinfo = action.payload;
            const { id, updatedQuantity } = packinfo;

            const indexOfPackage = state.packageItemsInCart.findIndex(
                (i) => i.packageid === id
            );
            // const currentPackage = {
            //     ...state.packageItemsInCart[indexOfPackage],
            //     quantity: updatedQuantity,
            // };
            // console.log(
            //     `currentPackage to set is: ${JSON.stringify(currentPackage)}`,
            // );
            state.packageItemsInCart[indexOfPackage].quantity = updatedQuantity;
        },
        setCardToCharge(state, action) {
            return {
                ...state,
                cardtocharge: action.payload,
            };
        },
        setTotalToChargeAfterCreditAndDiscounts(state, action) {
            return {
                ...state,
                totalToChargeAfterCreditAndDiscounts: action.payload,
            };
        },
        setCreditApplied(state, action) {
            // const currentCredit = state.creditApplied;
            const newCredit = action.payload;
            return {
                ...state,
                creditApplied: newCredit,
            };
        },
        setTotalAfterCreditApplied(state, action) {
            return {
                ...state,
                totalAfterCreditApplied: action.payload,
            };
        },
        setCardBrand(state, action) {
            return {
                ...state,
                cardbrand: action.payload,
            };
        },
        setExpy(state, action) {
            return {
                ...state,
                expy: action.payload,
            };
        },
        setLastFourCard(state, action) {
            return {
                ...state,
                lastfour: action.payload,
                cardlastfour: action.payload,
            };
        },
        addPackageToCart(state, action) {
            const { packageinfo, dibsId } = action.payload;
            let taxamount = 0;
            if (packageinfo.id > 0) {
                taxamount = packageinfo.price * state.taxrateforclasses;
            }
            let needtoaddsubscription = false;
            if (packageinfo.autopay !== 'NONE') {
                needtoaddsubscription = true;
            }

            const valuetoadd = {
                packageid: packageinfo.id,
                packageName: packageinfo.packageName,
                numClasses: packageinfo.numClasses,
                price: packageinfo.price,
                autopay: packageinfo.autopay,
                quantity: 1,
                dibsStudioId: dibsId,
                autopayIncrement: packageinfo.autopayIncrement,
                unlimited: packageinfo.unlimited,
                private: packageinfo.private,
                onDemandAccess: packageinfo.onDemandAccess,
                validity: packageinfo.validity,
                validIncrement: packageinfo.validIncrement,
                commitmentPeriod: packageinfo.commitmentPeriod,
                purchaseLimit: packageinfo.purchaseLimit,
                itemtax: taxamount,
            };
            const alreadyInCart = state.packageItemsInCart.find(
                (i) => i.packageid === packageinfo.id
            );

            if (alreadyInCart) {
                alreadyInCart.quantity += 1;
                alreadyInCart.dibsStudioId = dibsId;
            } else {
                state.packageItemsInCart.push(valuetoadd);
            }
            // const newsubtotal = state.subtotal + packageinfo.price;
            // console.log(`newsubtotal is: ${newsubtotal}`);
            // const newtax = state.tax + taxamount;
            // console.log(`newtax is: ${newtax}`);
            // state.subtotal = newsubtotal;
            // state.tax = newtax;
            // state.total = newsubtotal + newtax;
        },
        addToCart(state, action) {
            const { event, dibsId, howtopay } = action.payload;
            const item = event;
            let taxamount = 0;
            let pricetouse = 0;
            if (event.id > 0) {
                taxamount = item.price * state.taxrateforclasses;
                taxamount = roundTo(taxamount, 2);
                pricetouse = item.price;
            }
            // if (howtopay.passid !== 0) {
            //     taxamount = 0;
            //     pricetouse = 0;
            // }
            const valuetoadd = {
                eventid: item.id,
                eventTime: item.eventTime,
                eventName: item.eventName,
                quantity: 1,
                dibsStudioId: dibsId,
                startDate: item.startDate,
                private: item.private,
                price: pricetouse,
                locationName: item.locationName,
                instructorName: item.instructorName,
                itemtax: taxamount,
                howtopay: howtopay,
            };
            const itemInCart = state.itemsInCart.find(
                (i) => i.eventid === item.id
            );
            if (itemInCart) {
                itemInCart.quantity += 1;
            } else {
                state.itemsInCart.push(valuetoadd);
            }
        },
        setItemsInCartWithPasses(state, action) {
            const eventItems = action.payload;
            // state.itemsInCart = eventItems;
            eventItems.forEach((itemincart) => {
                const index = state.itemsInCart.findIndex(
                    (i) => i.eventid === itemincart.eventid
                );

                if (index > -1) {
                    state.cartPreparedForCheckout[index] = itemincart;
                } else {
                    state.cartPreparedForCheckout.push(itemincart);
                }
            });
        },
        updateCartTotals(state, action) {
            const { subtotal, tax, total } = action.payload;
            state.subtotal = subtotal;
            state.tax = tax;
            state.total = total;
        },
        setPurchasingPackage(state, action) {
            const { packageid, isPurchasing } = action.payload;
            return {
                ...state,
                purchasingPackageId: packageid,
                isPurchasingAPackage: isPurchasing,
            };
        },
        removeFromCart(state, action) {
            const id = action.payload;
            const itemInCart = state.itemsInCart.find((i) => i.id === id);
            if (itemInCart.quantity > 1) {
                itemInCart.quantity -= 1;
            } else {
                state.itemsInCart = state.itemsInCart.filter(
                    (i) => i.id !== id
                );
            }
        },
        deleteEventItemCompletelyFromCart(state, action) {
            const eventid = action.payload;
            state.itemsInCart = state.itemsInCart.filter(
                (i) => i.eventid !== eventid
            );
            state.cartPreparedForCheckout =
                state.cartPreparedForCheckout.filter(
                    (i) => i.eventid !== eventid
                );
        },
        deletePackageItemCompletelyFromCart(state, action) {
            const pack = action.payload;
            const quantity = pack.quantity;
            state.packageItemsInCart = state.packageItemsInCart.filter(
                (i) => i.packageid !== pack.packageid
            );
            const pricequantity = pack.price * quantity;
            const newsubtotal = Math.max(state.subtotal - pricequantity, 0);
            const taxquantity = pack.itemtax * quantity;
            // const newtax = state.tax - taxquantity;
            const newtax = Math.max(state.tax - taxquantity, 0);
            const newtotal = newsubtotal + newtax;
            state.subtotal = newsubtotal;
            state.tax = newtax;
            state.total = newtotal;
        },
        clearCart(state, action) {
            const dibsId = action.payload;
            state.itemsInCart = state.itemsInCart.filter(
                (it) => it.dibsStudioId !== dibsId
            );
            state.cartPreparedForCheckout = state.itemsInCart.filter(
                (it) => it.dibsStudioId !== dibsId
            );
            state.packageItemsInCart = state.packageItemsInCart.filter(
                (it) => it.dibsStudioId !== dibsId
            );
            state.total = 0;
        },
        clearPaymentInfoFromCart(state, action) {
            return {
                ...state,
                cardtocharge: null,
                cardbrand: null,
                lastfour: null,
                expy: null,
                creditApplied: 0,
                cardlastfour: null,
                purchasingPackageId: null,
                tax: 0,
                subtotal: 0,
                total: 0,
                totalAfterCreditApplied: 0,
                totalBeforeCreditApplied: 0,
                totalToChargeAfterCreditAndDiscounts: 0,
                isPurchasingAPackage: false,
            };
        },
    },
});
export default cart.reducer;
export function setDiscount(code, total) {
    return async () => {
        try {
            const response = await axios.post(getApiUrl('/cart/discount'), {
                code,
                total,
            });
            dispatch(cart.actions.setDiscountSuccess(response.data));
        } catch (error) {
            dispatch(cart.actions.hasError(error));
        }
    };
}
export function resetCart() {
    return async () => {
        try {
            const response = await axios.post(getApiUrl('/cart/reset'));
            dispatch(cart.actions.resetCardSuccess(response.data));
        } catch (error) {
            dispatch(cart.actions.hasError(error));
        }
    };
}
export const {
    addToCart,
    removeFromCart,
    setCardToCharge,
    setCreditApplied,
    setPurchasingPackage,
    setTotalAfterCreditApplied,
    setCardBrand,
    setExpy,
    setLastFourCard,
    setApptsBeingConfirmed,
    setTotal,
    setSubTotal,
    setTotalTax,
    setDiscountedPackageInfo,
    clearCart,
    addPackageToCart,
    setNeedsToPay,
    updateQuantityInCart,
    updatePackageQuantityInCart,
    deleteEventItemCompletelyFromCart,
    deletePackageItemCompletelyFromCart,
    setTotalToChargeAfterCreditAndDiscounts,
    setItemsInCartWithPasses,
    setTotalBeforeCreditApplied,
    clearPaymentInfoFromCart,
} = cart.actions;
