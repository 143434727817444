import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function confirmAvailabilityInstructor(
    newAppt,
    multiAppts
) {
    const url = getApiUrl('/confirm-instructor-availability');
    const passindata = {
        newAppt,
        multiAppts,
    };

    const { data } = await axios.post(url, passindata);

    const { success } = data;

    if (!success) {
        return {
            errorMsg: data.errorMsg,
            success: false,
        };
    } else {
        return {
            success: true,
            errorMsg: null,
            startTimeOfFullAppt: data.startTimeOfFullAppt,
            endTimeOfFullAppt: data.endTimeOfFullAppt,
        };
    }
}
