import axios from 'axios';
import { getApiUrl } from 'helpers';
// this sends cancellation emails to multiple users

export default async function sendUpdateSmss(
    attendeeList,
    eventIdEditing,
    studioEmployeeId,
    locationCreated
) {
    const url = getApiUrl('/twilio/send-update');
    let response;
    await axios
        .post(url, {
            attendeeList,
            eventIdEditing,
            eventId: eventIdEditing,
            studioEmployeeId,
            locationCreated,
        })
        .then((res) => {
            const { data } = res;
            response = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - update message via sms twilio',
                error
            );
        });
    return response;
}
