// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'axios';
// import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    profile: {
        birthday: 'Not Provided',
        clientSecretSetupIntent: '',
        creditAmount: 0,
        email: '',
        firstname: '',
        lastname: '',
        name: '',
        paymentMethodOnFile: false,
        paymentOptionsAll: [],
        phone: '',
        phonewithcountrycode: '',
        pictureUrl:
            'https://d1f9yoxjfza91b.cloudfront.net/dibs-user-placeholder.png',
        userid: 0,
    },
    communicationLogs: [],
    connectedStripeId: '',
    creditAmount: 0,
    currentCart: [],
    defaultCardBrand: null,
    defaultExpMonth: null,
    defaultExpYear: null,
    defaultLast4: null,
    defaultPmToUse: null,
    dibsStripeId: '',
    hasPaymentMethodOnFile: false,
    isClassesFetched: false,
    lastFetchedUserid: null,
    numVisits: 0,
    passesAvailable: [],
    passesUsedInCurrentCart: [],
    paymentMethodOnFile: false, // just in case something is referencing this
    pmsAvailable: [],
    refreshPaymentOptions: 0,
    refreshTransactions: false,
    showCommunicationLogs: false,
    refreshCommunicationLogs: false,
    upcomingClasses: [],
};

const currentclient = createSlice({
    name: 'currentclient',
    initialState,
    reducers: {
        // HAS ERROR
        hasError: (state, action) => {
            state.error = action.payload;
        },
        clearPreviousClient: (state) => {
            state.profile = {
                userid: 0,
                name: '',
                firstname: '',
                lastname: '',
                email: '',
                creditAmount: 0,
                paymentMethodOnFile: false,
                paymentOptionsAll: [],
                clientSecretSetupIntent: '',
                phone: '',
                phonewithcountrycode: '',
                birthday: 'Not Provided',
                pictureUrl:
                    'https://d1f9yoxjfza91b.cloudfront.net/dibs-user-placeholder.png',
            };
            state.dibsStripeId = '';
            state.connectedStripeId = '';
            state.hasPaymentMethodOnFile = false;
            state.paymentMethodOnFile = false;
            state.defaultPmToUse = null;
            state.defaultLast4 = null;
            state.defaultCardBrand = null;
            state.defaultExpMonth = null;
            state.defaultExpYear = null;
            state.refreshPaymentOptions = 0;
            state.pmsAvailable = [];
            state.passesAvailable = [];
            state.currentCart = [];
            state.passesUsedInCurrentCart = [];
            state.creditAmount = 0;
            state.upcomingClasses = [];
        },
        setRefreshCommunicationLogs: (state, action) => {
            return {
                ...state,
                refreshCommunicationLogs: action.payload,
            };
        },
        // ADD CURRENT CLIENT PROFILE IN STUDIO ADMIN
        setCurrentClientProfileStudioAdminShort: (state, action) => {
            const { id, label, firstname, lastname, email, birthday, phone } =
                action.payload;
            if (label) state.profile.name = label;
            if (id) state.profile.userid = id;
            if (firstname) {
                state.profile.firstname = firstname;
            }
            if (lastname) {
                state.profile.lastname = lastname;
            }
            if (email) state.profile.email = email;
            if (birthday?.length > 0) state.profile.birthday = birthday;
            if (phone) state.profile.phone = phone;
            // state.userid = id;
            // state.email = action.payload.email;
            // state.phone = action.payload.labelphone;
        },

        setLastFetchedUserid: (state, action) => {
            return {
                ...state,
                lastFetchedUserid: action.payload,
            };
        },
        setClassesFetched: (state, action) => {
            return {
                ...state,
                isClassesFetched: action.payload,
            };
        },
        setRefreshTransactions: (state, action) => {
            return {
                ...state,
                refreshTransactions: action.payload,
            };
        },
        setNumVisits: (state, action) => {
            return {
                ...state,
                numVisits: action.payload,
            };
        },
        setCommunicationsLogs: (state, action) => {
            const { communicationLogs } = action.payload;

            if (communicationLogs?.length > 0) {
                // const newlogs = communicationLogs.sort((a, b) => {
                //     return new Date(b.sentAt) - new Date(a.sentAt);
                // });
                const newCommunicationLog = [...communicationLogs];

                state.communicationLogs = newCommunicationLog;
                state.showCommunicationLogs = true;
            } else {
                state.communicationLogs = [];
                state.showCommunicationLogs = false;
            }
            state.refreshCommunicationLogs = true; // not doing anything with this yet. just seeing if it is effective.
        },
        setCurrentClientProfileStudioAdmin: (state, action) => {
            const {
                id,
                label,
                firstName,
                firstname,
                lastname,
                email,
                labelphone,
                lastName,
                phoneCountryCode,
                pictureUrl,
                birthday,
                communicationLogs,
            } = action.payload;

            state.profile.name = label;
            state.profile.userid = id;
            if (email?.length > 4) {
                state.profile.email = email;
            }
            if (communicationLogs?.length > 0) {
                const newlogs = communicationLogs.sort((a, b) => {
                    return new Date(b.sentAt) - new Date(a.sentAt);
                });

                state.communicationLogs = newlogs;
                state.showCommunicationLogs = true;
            } else {
                state.communicationLogs = [];
                state.showCommunicationLogs = false;
            }

            if (labelphone) {
                state.profile.phone = labelphone;
                state.phone = labelphone;
            }
            if (phoneCountryCode) {
                state.profile.phonewithcountrycode = phoneCountryCode;
            }
            if (firstName) {
                state.profile.firstname = firstName;
            }
            if (firstname) {
                state.profile.firstname = firstname;
            }
            if (lastName) {
                state.profile.lastname = lastName;
            }
            if (lastname) {
                state.profile.lastname = lastname;
            }
            state.userid = id;
            if (email) {
                state.email = email;
            }
            if (pictureUrl) {
                state.profile.pictureUrl = pictureUrl;
            }
            if (birthday?.length > 0) {
                state.profile.birthday = birthday;
            }
        },
        setPassesAvailable(state, action) {
            return {
                ...state,
                passesAvailable: action.payload,
            };
        },
        setRefreshPaymentOptions(state, action) {
            return {
                ...state,
                refreshPaymentOptions: state.refreshPaymentOptions + 1,
            };
        },
        setCurrentCreditAmount: (state, action) => {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    creditAmount: action.payload,
                },
                creditAmount: action.payload,
            };
            // state.userid = id;
            // state.email = action.payload.email;
            // state.phone = action.payload.labelphone;
        },
        addToCurrentCart: (state, action) => {
            const currentCart = state.currentCart;
            const newcart = [...currentCart, action.payload];
            return {
                ...state,
                currentCart: newcart,
            };
        },
        setUpcomingClasses: (state, action) => {
            return {
                ...state,
                upcomingClasses: action.payload,
            };
        },
        updatePassToBeUSedInCart: (state, action) => {
            const currentCart = state.currentCart;
            const { eventid, newpass } = action.payload;
            const newcart = currentCart.map((item) => {
                if (item.eventid === eventid) {
                    return {
                        ...item,
                        pass: newpass,
                    };
                }
                return item;
            });
            return {
                ...state,
                currentCart: newcart,
            };
        },
        updateCurrentCartSpotsRequested: (state, action) => {
            const currentCart = state.currentCart;
            const { eventid, spotsRequestedToBook } = action.payload;
            const newcart = currentCart.map((item) => {
                if (item.eventid === eventid) {
                    return {
                        ...item,
                        spotsRequestedToBook,
                    };
                }
                return item;
            });
            return {
                ...state,
                currentCart: newcart,
            };
        },
        clearCurrentCart: (state, action) => {
            return {
                ...state,
                currentCart: [],
            };
        },
        removeFromCurrentCart: (state, action) => {
            const currentCart = state.currentCart;
            const classRemoving = action.payload;
            const newcart = currentCart.filter(
                (item) => item.eventid !== classRemoving.eventid
            );
            return {
                ...state,
                currentCart: newcart,
            };
        },
        setStripeInfoCurrentClient: (state, action) => {
            const { dibsStripeId, connectedStripeId } = action.payload;

            return {
                ...state,
                dibsStripeId,
                connectedStripeId,
            };
        },
        setHasPaymentMethodOnFile: (state, action) => {
            return {
                ...state,
                paymentMethodOnFile: action.payload,
                hasPaymentMethodOnFile: action.payload,
                profile: {
                    ...state.profile,
                    paymentMethodOnFile: action.payload,
                },
            };
        },
        setPaymentOptionsAll: (state, action) => {
            return {
                ...state,
                paymentOptionsAll: action.payload,
            };
        },
        setPmsAvailable: (state, action) => {
            return {
                ...state,
                pmsAvailable: action.payload,
            };
        },
        pushIntoPmsAvailable: (state, action) => {
            const pmsAvailableCurrent = state.pmsAvailable;
            const newPmsAvailable = [...pmsAvailableCurrent, action.payload];
            return {
                ...state,
                pmsAvailable: newPmsAvailable,
            };
        },
        setClientSecretSetupIntent: (state, action) => {
            return {
                ...state,
                clientSecretSetupIntent: action.payload,
            };
        },
        setDefaultPmToUse: (state, action) => {
            return {
                ...state,
                defaultPmToUse: action.payload,
            };
        },
        setDefaultPmDetails: (state, action) => {
            const { last4, brand, exp_month, exp_year } = action.payload;
            return {
                ...state,
                defaultLast4: last4,
                defaultCardBrand: brand,
                defaultExpMonth: exp_month,
                defaultExpYear: exp_year,
            };
        },
    },
});

// Reducer
export default currentclient.reducer;
export const {
    setCurrentClientProfileStudioAdmin,
    hasError,
    setClassesFetched,
    setCurrentCreditAmount,
    setCommunicationsLogs,
    setRefreshPaymentOptions,
    setPassesAvailable,
    clearCurrentCart,
    setUpcomingClasses,
    setNumVisits,
    setHasPaymentMethodOnFile,
    setStripeInfoCurrentClient,
    setCurrentClientProfileStudioAdminShort,
    removeFromCurrentCart,
    setPaymentOptionsAll,
    updateCurrentCartSpotsRequested,
    setClientSecretSetupIntent,
    setDefaultPmToUse,
    setDefaultPmDetails,
    setPmsAvailable,
    clearPreviousClient,
    updatePassToBeUSedInCart,
    setRefreshTransactions,
    addToCurrentCart,
    pushIntoPmsAvailable,
    setRefreshCommunicationLogs,
    setLastFetchedUserid,
} = currentclient.actions;

// ----------------------------------------------------------------------
