import axios from 'axios';
import { getApiUrl } from 'helpers';
// this sends cancellation emails to multiple users

export default async function sendCancelSmss(
    attendeeList,
    eventidCancelling,
    studioEmployeeId
) {
    const url = getApiUrl('/twilio/send-cancels');
    let response;
    await axios
        .post(url, {
            attendeeList,
            eventid: eventidCancelling,
            studioEmployeeId,
            cancelingFromLocation: 'studio-admin',
        })
        .then((res) => {
            const { data } = res;
            response = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - update multi cancellation sms twilio',
                error
            );
        });
    return response;
}
