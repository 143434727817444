// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import dibsstudioReducer from './slices/dibsstudio';
import scheduleReducer from './slices/schedule';
import menuReducer from './slices/menu';
import dashboardReducer from './slices/dashboard';
import packagesReducer from './slices/packages';
import cartReducer from './slices/cart';
import snackbarReducer from './slices/snackbar';
import studioemployeeuserReducer from './slices/studioemployeeuser';
import errorReducer from './slices/error';
import successReducer from './slices/success';
import clientsearchReducer from './slices/clientsearch';
import thisuserReducer from './slices/thisuser';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import userReducer from './slices/user';
import actionstatusReducer from './slices/actionstatus';
import currentclientReducer from './slices/currentclient';
import retailReducer from './slices/retail';
import datatablesReducer from './slices/datatables';
import sessionReducer from './slices/session';
import apptsReducer from './slices/appointments';
import instructorReducer from './slices/instructors';
import loadingReducer from './slices/loadingStatus';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    dibsstudio: persistReducer(
        { key: 'dibsstudio', storage, keyPrefix: 'dibsstudio-' },
        dibsstudioReducer
    ),
    dashboard: persistReducer(
        { key: 'dibsdashboard', storage, keyPrefix: 'dibsdashboard-' },
        dashboardReducer
    ),
    studioemployeeuser: persistReducer(
        { key: 'studioemployeeuser', storage },
        studioemployeeuserReducer
    ),
    menu: menuReducer,
    actionstatus: actionstatusReducer,
    customer: customerReducer,
    // currentclient: currentclientReducer,
    currentclient: persistReducer(
        { key: 'dibs', storage, keyPrefix: 'dibs-' },
        currentclientReducer
    ),
    schedule: persistReducer(
        { key: 'schedule', storage, keyPrefix: 'dibs-' },
        scheduleReducer
    ),
    retail: retailReducer,
    datatables: persistReducer(
        { key: 'datatables', storage, keyPrefix: 'datatables-' },
        datatablesReducer
    ),
    calendar: calendarReducer,
    packages: packagesReducer,
    appointments: apptsReducer,
    instructors: persistReducer(
        {
            key: 'instructors',
            storage,
            keyPrefix: 'dibs-',
        },
        instructorReducer
    ),
    loadingStatus: loadingReducer,
    cart: persistReducer({ key: 'dibscart', storage }, cartReducer),
    snackbar: snackbarReducer,
    error: errorReducer,
    success: successReducer,
    clientsearch: persistReducer(
        {
            key: 'clientsearch',
            storage,
        },
        clientsearchReducer
    ),
    thisuser: thisuserReducer,
    contact: contactReducer,
    product: productReducer,
    user: userReducer,
    session: persistReducer(
        { key: 'session', storage, keyPrefix: 'dibs-' },
        sessionReducer
    ),
});

export default reducer;
