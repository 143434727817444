import axios from 'axios';
import { getApiUrl } from 'helpers';

// search here for widget code help:  sendOpsEmail

export const sendOpsEmail = async ({
    userid,
    dibsId,
    eventid,
    typeofevent,
    subject,
    relevantId,
    idStringName,
    action,
    extraInfo,
    studioEmployeeId,
    fromWhere,
}) => {
    // test
    const url = getApiUrl('/send-stand-alone-ops');
    try {
        const res = await axios.post(url, {
            userid,
            dibsId,
            eventid,
            typeofevent,
            subject,
            relevantId,
            idStringName,
            action,
            extraInfo,
            studioEmployeeId,
            fromWhere,
        });
        if (res) {
            return res.data;
        } else {
            return 0;
        }
    } catch (err) {
        console.log(
            `error sending stand along email ops notification. Error is: ${err}`
        );
        return 0;
    }
};

export default sendOpsEmail;
