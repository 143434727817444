import React, { useState } from 'react';

import { Grid, TextField, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'store';
import validator from 'email-validator';
import { validatePhone } from 'helpers/general';
import createNewDibsUser from 'actions/users/createNewDibsUser';
import createNewShopifyUser from 'actions/shopify/createNewShopifyUser';
import recordShopifyStatus from 'actions/shopify/recordShopifyStatus';

import { capitalizeFirstLetter } from 'helpers/general';

const CreateAccountComponent = ({
    setHideSaveButtons,
    setIsAddingNewClient,
    handleClientSelection,
    setAttendeeForThisEvent,
    setShowAutoCompleteClient,
}) => {
    const theme = useTheme();
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [allowManagerAccess, setAllowManagerAccess] = useState(false);

    const [checkedManager, setCheckedManager] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [timeoutArray, setTimeoutArray] = useState([]);
    React.useEffect(
        () => () => {
            timeoutArray.forEach((timeout) => {
                clearTimeout(timeout);
            });
        },
        [timeoutArray]
    );
    // not including instructor account for now because it is not high demand
    const handleCancel = () => {
        setHideSaveButtons(false);
        setIsAddingNewClient(false);
    };
    const clearData = () => {
        setPhone('');
        setEmail('');
        setFirstName('');
        setLastName('');
        setCheckedManager(false);
        setAllowManagerAccess(false);
    };
    const handleErrorProcess = (errorMsg) => {
        setHasSuccess(false);
        setErrorMessage(errorMsg);
        setHasError(true);
        const timeoutiderror = setTimeout(() => {
            setHasError(false);
            setErrorMessage('');
        }, 10000);
        setTimeoutArray([...timeoutArray, timeoutiderror]);
    };

    const handleTextChange = (e) => {
        if (e.target.id === 'phone') setPhone(e.target.value);
        if (e.target.id === 'email') setEmail(e.target.value);
        if (e.target.id === 'firstname') setFirstName(e.target.value);
        if (e.target.id === 'lastname') setLastName(e.target.value);
        if (hasError) setHasError(false);
        if (hasSuccess) setHasSuccess(false);
    };
    const handleSubmit = async () => {
        const isValidEmail = validator.validate(email);
        if (!isValidEmail) {
            handleErrorProcess(
                `The email address you entered doesn't seem to be valid. Can you try again?`
            );
            return;
        }
        if (phone?.length > 2) {
            const isValidPhone = validatePhone(phone);
            if (!isValidPhone) {
                handleErrorProcess(
                    `The phone number you entered doesn't seem to be valid. Can you try again?`
                );
                return;
            }
        }
        const datatosend = {
            email,
            firstname: firstName,
            lastname: lastName,
            phone,
            birthday: null,
            studioEmployeeId,
            dibsId: dibsStudioId,
            countryAbbr: 'US',
        };

        const newDibsUser = await createNewDibsUser(datatosend);

        const { userid } = newDibsUser;

        if (dibsStudioId === '226' || dibsStudioId === '260') {
            const newpwd = `Dibs!1234${Math.floor(Math.random() * 100000)}`;
            const thisdata = {
                dibsStudioId,
                email,
                phone,
                newpwd,
            };
            const newShopifyUser = await createNewShopifyUser(thisdata, userid);
            if (newShopifyUser) {
                console.log('\n\n\nnew shopify user is', newShopifyUser);
                const { createdNewCustomer } = newShopifyUser;
                if (createdNewCustomer) {
                    console.log(
                        'now we are here REGISTERING That we created the new customer'
                    );
                    const updatedDb = await recordShopifyStatus(
                        newShopifyUser,
                        userid
                    );
                    console.log('updatedDb is', updatedDb);
                }
            } else {
                console.log('something wonky is happening with shopify');
                handleErrorProcess(
                    'There was an error creating the new client'
                );
            }
        }
        if (userid) {
            setHideSaveButtons(false);
            setIsAddingNewClient(false);
            const fname = capitalizeFirstLetter(firstName);
            const lname = capitalizeFirstLetter(lastName);
            const clientdata = {
                firstname: fname,
                firstName: fname,
                lastname: lname,
                lastName: lname,
                email,
                phone,
                mobilephone: phone,
                label: `${fname} ${lname}`,
                id: userid,
                key: userid,
            };
            const attendeearray = [];
            attendeearray.push(clientdata);
            setAttendeeForThisEvent(attendeearray);
            setShowAutoCompleteClient(false);
            handleClientSelection(clientdata, 'fromCreateClient');
        }
    };
    return (
        <Grid container direction="row" border={0}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h5">
                    Create New Client
                </Typography>
            </Grid>
            {hasError && (
                <Grid item xs={12}>
                    <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Typography>
                </Grid>
            )}
            {hasSuccess && (
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{ mb: 2, color: theme.palette.success.main }}
                    >
                        {successMessage}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} md={5} size="small" sx={{ mt: 2, mr: 2 }}>
                <TextField
                    required
                    fullWidth
                    id="firstname"
                    label="First Name"
                    value={firstName}
                    variant="outlined"
                    onChange={(e) => handleTextChange(e)}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} md={5} sx={{ mt: 2 }}>
                <TextField
                    required
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    value={lastName}
                    variant="outlined"
                    onChange={(e) => handleTextChange(e)}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} md={8} sx={{ mt: 3, mr: 2 }}>
                <TextField
                    fullWidth
                    required
                    id="email"
                    label="Email"
                    value={email}
                    variant="outlined"
                    onChange={(e) => handleTextChange(e)}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} md={7} sx={{ mt: 3 }}>
                <TextField
                    id="phone"
                    fullWidth
                    label="Phone Number"
                    value={phone}
                    variant="outlined"
                    onChange={(e) => handleTextChange(e)}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sx={{ mt: 3, mb: 4 }}>
                <Grid container justifyContent="flex-start">
                    <Grid item sx={{ mr: 2 }}>
                        <Button
                            id="cancel-new-client-account-studio-admin"
                            variant="cancel"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            id="create-client-account-studio-admin"
                            onClick={handleSubmit}
                        >
                            Create A New Client
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CreateAccountComponent;
