import axios from 'axios';
import { getApiUrl } from 'helpers';
import craftSMSMessage from 'actions/twilio/craftSMSMessage';

export default async function sendSmsConfirmation(datareceived) {
    const url = getApiUrl('/twilio/notify');

    const msg = await craftSMSMessage(datareceived);
    const { appttocreate, eventscreated } = datareceived;
    const { apptToConfirm, studioEmployeeId } = appttocreate;
    const { mobilephone, dibsStudioId, twilioCustomNumUS, userid } =
        apptToConfirm;
    if (!mobilephone) {
        console.log('no mobilephone');
        return {
            success: true,
            earlyreturn: true,
            msg: `I could not send the sms because I don't see a phone number on file for this user.`,
            code: 5,
        };
    }
    const twiliodata = {
        phoneNumberTo: mobilephone,
        customTwilioNumUS: twilioCustomNumUS,
        twilioMsg: msg,
        dibsId: Number(dibsStudioId),
        userid,
        typeOfMsg: 'confirmation',
        studioEmployeeId,
        locationCreated: 'studio-admin',
        eventidCreated: eventscreated[0].eventid,
    };
    const twilioResponse = await axios.post(url, twiliodata);
    if (twilioResponse) {
        const { data } = twilioResponse;
        return data;
    } else {
        console.log('no twilio response');
        return null;
    }
}
