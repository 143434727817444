import { dispatch } from 'store';
import {
    setInstructorAltName,
    setStudioName,
    setCustomerServicePhone,
    setTwilioCustomNumUS,
    setShowApptsOnWidget,
    setShowApptsOnAdmin,
} from 'store/slices/dibsstudio';

export default async function setUserInStore(studiodata) {
    // console.log('studiodata is --> ', studiodata);
    const { customerServicePhone, twilioCustomNumUS, showAppts } = studiodata;
    dispatch(setInstructorAltName(studiodata.instructorAltName));
    dispatch(setStudioName(studiodata.studioName));
    dispatch(setCustomerServicePhone(customerServicePhone));
    if (twilioCustomNumUS) dispatch(setTwilioCustomNumUS(twilioCustomNumUS));

    dispatch(setShowApptsOnWidget(showAppts));
    dispatch(setShowApptsOnAdmin(showAppts));
    return 1;
}
