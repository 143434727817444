import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    List,
    ListItemIcon,
    ListItemButton,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    Fade,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from 'assets/icons/checkmarkIcon';

import ConfirmClientRemoval from 'components/schedule-sections/confirmClientRemoval';

import { capitalizeFirstLetter } from 'helpers/general';
import compareApptTimeToNow from 'helpers/dayjs/compareNowToApptTime.js';
import updateAttendanceClient from 'actions/schedule/updateAttendance';

import { useSelector } from 'store';

const BookedClients = ({
    attendees,
    setHideSaveButtons,
    thiseventid,
    dibsId,
    startTime,
    setUpdateEvents,
    handleClientSelection,
    eventType,
    setClientSelected,
    handleChangeWasMade,
    refreshAttendees,
}) => {
    const [isChangingClient, setIsChangingClient] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const [showCheckinText, setShowCheckinText] = useState(false);
    const { timeZone } = useSelector((state) => state.dibsstudio.config);
    const [attendeesToShow, setAttendeesToShow] = useState(attendees);
    const [showConfirmationText, setShowConfirmationText] = useState(false);
    const [statusRecentlyChanged, setStatusRecentlyChanged] = useState([]);

    useEffect(() => {
        const timeDifference = compareApptTimeToNow(
            startTime,
            timeZone,
            'hours'
        );
        if (timeDifference < 10) setShowCheckinText(true);
        else setShowCheckinText(false);
    }, [startTime, timeZone]);
    useEffect(() => {
        if (attendees) {
            setAttendeesToShow(attendees);
        } else {
            setAttendeesToShow([]);
        }
    }, [attendees]);

    if (!attendeesToShow) return null;
    const removeClient = (client) => {
        setCurrentClient(client);
        setHideSaveButtons(true);
        setIsChangingClient(true);
        handleClientSelection(client, 'fromBookedClients');
    };
    const cancelRemovingClient = () => {
        setHideSaveButtons(false);
        setIsChangingClient(false);
    };

    if (isChangingClient)
        return (
            <ConfirmClientRemoval
                currentClient={currentClient}
                cancelRemovingClient={cancelRemovingClient}
                thiseventid={thiseventid}
                handleChangeWasMade={handleChangeWasMade}
                dibsId={dibsId}
                startTime={startTime}
                setUpdateEvents={setUpdateEvents}
                eventType={eventType}
                setClientSelected={setClientSelected}
            />
        );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ p: 0, m: 0 }}
                id="item-holding-list-booked-clients"
            >
                <List>
                    <Grid
                        key="booked-clients-list-container"
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {attendeesToShow.map((attendee, i) => {
                            const {
                                firstname,
                                userid,
                                lastname,
                                email,
                                mobilephone,
                                checkedin,
                            } = attendee;

                            let phonetouse = mobilephone;
                            if (!phonetouse) phonetouse = 'No phone on file';
                            return (
                                <React.Fragment
                                    key={`book-client-fragment-${userid}-${i}`}
                                >
                                    <Grid
                                        item
                                        xs={11}
                                        border={0}
                                        sx={{ m: 0 }}
                                        id="item-holding-list-item-booked-client"
                                    >
                                        <ListItem
                                            key={`listitem-${userid}`}
                                            id={`listitem-userid-${userid}`}
                                            sx={{ pl: 0 }}
                                        >
                                            <ListItemButton
                                                sx={{ p: 0 }}
                                                key={`listbutton-${userid}`}
                                                id={`listbutton-userid-${userid}`}
                                            >
                                                <ListItemIcon
                                                    sx={{ minWidth: '22px' }}
                                                    key={`listitemicon-${userid}`}
                                                    id={`listitemicon-userid-${userid}`}
                                                >
                                                    <CircleIcon
                                                        color="secondary"
                                                        sx={{ fontSize: 10 }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    key={`listitemtext-${userid}`}
                                                    id={`listitemtext-userid-${userid}`}
                                                    primary={
                                                        <>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                            >
                                                                {showConfirmationText &&
                                                                    statusRecentlyChanged.includes(
                                                                        userid
                                                                    ) && (
                                                                        <Fade
                                                                            in
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                id={`checkedintextfor-${userid}`}
                                                                                sx={{
                                                                                    mb: 0.75,
                                                                                }}
                                                                            >
                                                                                <Typography variant="successText">
                                                                                    {capitalizeFirstLetter(
                                                                                        firstname
                                                                                    )}{' '}
                                                                                    is{' '}
                                                                                    {checkedin
                                                                                        ? 'checked'
                                                                                        : 'no longer checked'}{' '}
                                                                                    in
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Fade>
                                                                    )}

                                                                <Grid
                                                                    item
                                                                    id={`bookedclientsname-${userid}`}
                                                                    xs={12}
                                                                    border={0}
                                                                    onClick={async () => {
                                                                        if (
                                                                            checkedin
                                                                        ) {
                                                                            const returnvalue =
                                                                                await updateAttendanceClient(
                                                                                    attendee.id,
                                                                                    false,
                                                                                    userid
                                                                                );
                                                                            console.log(
                                                                                'return value after removing checked in status',
                                                                                returnvalue
                                                                            );
                                                                            if (
                                                                                returnvalue?.success
                                                                            ) {
                                                                                const newArray =
                                                                                    [
                                                                                        ...statusRecentlyChanged,
                                                                                    ];
                                                                                newArray.push(
                                                                                    userid
                                                                                );
                                                                                setStatusRecentlyChanged(
                                                                                    newArray
                                                                                );
                                                                                refreshAttendees(
                                                                                    thiseventid
                                                                                );
                                                                                setShowConfirmationText(
                                                                                    true
                                                                                );
                                                                                setTimeout(
                                                                                    () => {
                                                                                        setShowConfirmationText(
                                                                                            false
                                                                                        );
                                                                                        setStatusRecentlyChanged(
                                                                                            []
                                                                                        );
                                                                                    },
                                                                                    3500
                                                                                );
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <Stack direction="row">
                                                                        <Typography variant="bookedclientsname">
                                                                            {`${capitalizeFirstLetter(firstname)} ${capitalizeFirstLetter(lastname)}`}
                                                                        </Typography>{' '}
                                                                        {checkedin && (
                                                                            <CheckCircleIcon />
                                                                        )}
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    border={0}
                                                                    sx={{
                                                                        mr: 1.5,
                                                                    }}
                                                                >
                                                                    <Typography variant="bookedclients">
                                                                        {email}
                                                                    </Typography>
                                                                </Grid>
                                                                <Divider
                                                                    orientation="vertical"
                                                                    sx={{
                                                                        borderColor:
                                                                            '#ccc',
                                                                    }}
                                                                    flexItem
                                                                />
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        ml: 1.5,
                                                                    }}
                                                                    border={0}
                                                                >
                                                                    <Typography variant="bookedclients">
                                                                        {
                                                                            phonetouse
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        border={0}
                                        id={`clearicon-${userid}`}
                                        key={`clearicon-${userid}`}
                                    >
                                        <Grid
                                            container
                                            key={`cleariconcontainer-${userid}`}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                id={`clearicon-item-${userid}`}
                                                border={0}
                                                key={`clearicon-item-${userid}`}
                                            >
                                                <ClearIcon
                                                    key={`clearicon-icon-booked-${userid}`}
                                                    id={`clearicon-icon-booked-${userid}`}
                                                    color="secondary"
                                                    onClick={() =>
                                                        removeClient(attendee)
                                                    }
                                                    sx={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {showCheckinText && !checkedin && (
                                        <Grid
                                            item
                                            xs={11.5}
                                            id={`checkin-text-${userid}-${firstname}`}
                                            onClick={async () => {
                                                const newArray = [
                                                    ...statusRecentlyChanged,
                                                ];
                                                newArray.push(userid);
                                                setStatusRecentlyChanged(
                                                    newArray
                                                );
                                                const returnvalue =
                                                    await updateAttendanceClient(
                                                        attendee.id,
                                                        true,
                                                        userid
                                                    );

                                                if (returnvalue?.success) {
                                                    refreshAttendees(
                                                        thiseventid
                                                    );
                                                    setShowConfirmationText(
                                                        true
                                                    );
                                                    setTimeout(() => {
                                                        setShowConfirmationText(
                                                            false
                                                        );
                                                        setStatusRecentlyChanged(
                                                            []
                                                        );
                                                    }, 2500);
                                                }
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Grid
                                                container
                                                justifyContent="flex-end"
                                            >
                                                <Typography variant="successText">
                                                    Check In{' '}
                                                    {capitalizeFirstLetter(
                                                        firstname
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </List>
            </Grid>
        </Grid>
    );
};

export default BookedClients;
